import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { HeatmapRect } from '@visx/heatmap';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { ContainerColumn, ContainerRow } from '@src/components/styled';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
import DayCell from '@entities/wallet/ui/graphs/TradingActivity/DayCell';
const MonthContainer = styled(ContainerColumn)(props => ({
    width: 'auto',
    display: 'inline-flex',
    flex: '1 1 0',
    height: 'auto',
    gap: props.theme.spacing_sizes.xs * 0.5,
}));
const MonthGroup = styled.svg(props => ({
    width: props.weeksCount * 9 + (props.weeksCount - 1) * 3 + 4,
    height: 85,
}));
const ActivityHeatmapContainer = styled(ContainerRow)(() => ({
    justifyContent: 'space-between',
    flexWrap: 'wrap',
}));
const MonthName = styled.span(() => ({
    lineHeight: '15px',
    fontSize: 12,
    color: '#ABB4BB',
}));
const TradingActivityHeatmap = ({ data, isLoading = false, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const defineColorScale = useCallback((count) => {
        if (typeof count === 'number') {
            if (count >= 28) {
                return theme.palette.info.main;
            }
            if (count < 17 && count >= 10) {
                return '#96C7F2';
            }
            if (count > 0) {
                return theme.palette.info.light;
            }
            return '#EFF1F3';
        }
        return '#EFF1F3';
    }, [theme]);
    const monthNamesShort = useMemo(() => ({
        1: t('date_time.month.short.january', { ns: 'common' }),
        2: t('date_time.month.short.february', { ns: 'common' }),
        3: t('date_time.month.short.march', { ns: 'common' }),
        4: t('date_time.month.short.april', { ns: 'common' }),
        5: t('date_time.month.short.may', { ns: 'common' }),
        6: t('date_time.month.short.june', { ns: 'common' }),
        7: t('date_time.month.short.july', { ns: 'common' }),
        8: t('date_time.month.short.august', { ns: 'common' }),
        9: t('date_time.month.short.september', { ns: 'common' }),
        10: t('date_time.month.short.october', { ns: 'common' }),
        11: t('date_time.month.short.november', { ns: 'common' }),
        12: t('date_time.month.short.december', { ns: 'common' }),
    }), [t]);
    return (_jsx(ActivityHeatmapContainer, { children: _jsx(WithSkeleton, { isLoading: isLoading, width: '100%', height: 102, children: _jsx(HeatmapRect, { xScale: index => index, yScale: index => index, binWidth: 9, binHeight: 9, colorScale: defineColorScale, rx: 2, data: data, children: (cells) => cells.map((cellMonth, index) => (_jsxs(MonthContainer, { children: [_jsx(MonthGroup, { weeksCount: (cellMonth === null || cellMonth === void 0 ? void 0 : cellMonth.length) ? cellMonth[0].datum.weeksCount : 5, children: cellMonth.map(({ color, bin }) => (_jsx(DayCell, { color: color, data: bin }, bin.date))) }, `activity-month-group-${(cellMonth === null || cellMonth === void 0 ? void 0 : cellMonth.length) ? cellMonth[0].datum.monthOrder : index}`), _jsx(MonthName, { children: `${(cellMonth[0].datum.monthOrder &&
                                monthNamesShort[cellMonth[0].datum.monthOrder]) || ''}` })] }, `activity-month-container-${(cellMonth === null || cellMonth === void 0 ? void 0 : cellMonth.length) ? cellMonth[0].datum.monthOrder : index}`))) }) }) }));
};
export default TradingActivityHeatmap;
