var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
const TooltipOffset = -6;
const ArrowSkiddingValue = 10;
const GetPopperStyles = (theme, textColor, backgroundColor) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: backgroundColor,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        padding: '8px 10px',
        fontSize: 12,
        fontWeight: 500,
        color: textColor,
        backgroundColor,
        boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.08)',
    },
});
const TooltipStyled = (_a) => {
    var { colorVariant = 'black', tooltipYOffset = TooltipOffset } = _a, props = __rest(_a, ["colorVariant", "tooltipYOffset"]);
    const theme = useTheme();
    const textColor = useMemo(() => {
        let color;
        switch (colorVariant) {
            case 'black':
                color = theme.customColors.surface.surface;
                break;
            case 'white':
            default:
                color = theme.palette.text.primary;
        }
        return color;
    }, [colorVariant, theme]);
    const backgroundColor = useMemo(() => {
        let color;
        switch (colorVariant) {
            case 'black':
                color = theme.palette.text.primary;
                break;
            case 'white':
            default:
                color = theme.customColors.surface.surface;
        }
        return color;
    }, [colorVariant, theme]);
    const popperStyles = useMemo(() => GetPopperStyles(theme, textColor, backgroundColor), [textColor, backgroundColor, theme]);
    return (_jsx(MuiTooltip
    // eslint-disable-next-line react/jsx-props-no-spreading
    , Object.assign({}, props, { PopperProps: {
            sx: Object.assign({}, popperStyles),
            popperOptions: {
                modifiers: [
                    {
                        name: 'arrow',
                        options: {
                            padding: ArrowSkiddingValue,
                        },
                    },
                    {
                        name: 'offset',
                        options: {
                            offset: ({ placement }) => {
                                let skidding = 0;
                                if (String(placement).match('start')) {
                                    skidding = -ArrowSkiddingValue;
                                }
                                else if (String(placement).match('end')) {
                                    skidding = ArrowSkiddingValue;
                                }
                                return [skidding, tooltipYOffset];
                            },
                        },
                    },
                ],
            },
        }, componentsProps: {
            tooltip: {
                style: {
                    top: '-4px',
                    marginBottom: 0,
                },
            },
            arrow: {
                style: {
                    position: 'absolute',
                    top: '100%',
                    left: 'calc(50% - 5px)',
                    transform: 'none',
                },
            },
        } })));
};
export default TooltipStyled;
