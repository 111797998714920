import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { getLocale } from '@utils/date-time';
import { useAppSelector } from '@store/Hooks';
import TooltipStyled from './TooltipStyled';
const HoverRect = styled.rect(() => ({
    width: 11,
    height: 11,
    fill: 'transparent',
    strokeWidth: 0,
}));
const DayRect = styled.rect(() => ({
    width: 9,
    height: 9,
}));
const UnitGroup = styled.g(() => ({
    '&:hover': {
        [`${HoverRect}`]: {
            strokeWidth: 1,
        },
    },
}));
const DayCell = ({ color = undefined, data: { count, date, month, year, week, dayInWeek, } }) => {
    const { t } = useTranslation();
    const { language } = useAppSelector((state) => state.app);
    const activityCounter = `${count} ${t('plural_variables.closed_position', {
        ns: 'common',
        count,
    })}`;
    const activityDate = format(new Date(year, month, date), 'do MMMM', { locale: getLocale(language || 'en') });
    const tooltipTitle = t('pages.portfolios.general_portfolio_information.trading_activity.day_activity', {
        ns: 'common',
        activityCounter,
        activityDate,
    });
    return (_jsx(TooltipStyled, { title: tooltipTitle, placement: 'top', componentsProps: {
            tooltip: { style: { marginBottom: 0 } },
            arrow: {
                style: {
                    position: 'absolute',
                    top: '100%',
                    margin: 0,
                    width: 10,
                    height: 5,
                    left: 'calc(50% - 5px)',
                },
            },
        }, tooltipYOffset: 6, arrow: true, children: _jsxs(UnitGroup, { children: [_jsx(HoverRect, { stroke: color, x: ((week - 1) * 12) + 1, y: ((dayInWeek - 1) * 12) + 1, rx: '2' }), _jsx(DayRect, { fill: color, x: ((week - 1) * 12) + 2, y: ((dayInWeek - 1) * 12) + 2, rx: '2' })] }) }));
};
export default DayCell;
